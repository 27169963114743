import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Avatar, Card, CardBody, CardHeader, Divider, Button, Tooltip } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import { api } from '../services/api';
import VideoList, { Video } from './VideoList';
import { motion } from 'framer-motion';
import { useSnackbar } from 'notistack';
import SpinnerV from "./SpinnerV";

interface UserInfo {
  nickname: string;
  avatar: string;
  signature: string;
  fansCount: number;
  isFollowed: boolean;
}

interface UserResponse {
  userInfo: UserInfo;
  videos: Video[];
}

const CACHE_EXPIRATION = 5 * 60 * 1000; // 5 minutes

const UserProfile: React.FC = () => {
  const { userid } = useParams<{ userid: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState<UserResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFollowed, setIsFollowed] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isProcessing, setIsProcessing] = useState(false);

  const getCachedData = useCallback(() => {
    const cachedData = localStorage.getItem(`userProfile_${userid}`);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_EXPIRATION) {
        return data;
      }
    }
    return null;
  }, [userid]);

  const setCachedData = useCallback((data: UserResponse) => {
    localStorage.setItem(`userProfile_${userid}`, JSON.stringify({
      data,
      timestamp: Date.now()
    }));
  }, [userid]);

  const fetchUserData = useCallback(async (useCache = true) => {
    const cachedData = useCache ? getCachedData() : null;
    if (cachedData) {
      setUserData(cachedData);
      setIsFollowed(cachedData.userInfo.isFollowed);
    }

    setIsLoading(true);
    try {
      const response = await api.getUserInfo(userid || '');
      if (response.success && response.data) {
        const typedData = response.data as UserResponse;
        setUserData(typedData);
        setIsFollowed(typedData.userInfo.isFollowed);
        setCachedData(typedData);
      } else {
        console.error("Failed to fetch user info:", response.msg);
        enqueueSnackbar("Failed to load user information", { variant: "error" });
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      enqueueSnackbar("An error occurred while loading user data", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  }, [userid, enqueueSnackbar, getCachedData, setCachedData]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    const state = location.state as { scrollPosition?: number };
    if (state && typeof state.scrollPosition === 'number') {
      window.scrollTo(0, state.scrollPosition);
    }
  }, [location]);

  useEffect(() => {
    if (userData?.userInfo?.nickname) {
      document.title = `${userData.userInfo.nickname} - V Watch Video`;
    }
    return () => {
      document.title = 'V Watch Video';
    };
  }, [userData]);

  const handleVideoClick = useCallback((videoId: string) => {
    navigate(`/video/${videoId}`, {
      state: { scrollPosition: window.scrollY }
    });
  }, [navigate]);

  const handleFollowToggle = useCallback(async () => {
    if (!userid) return;
    setIsProcessing(true);

    try {
      const response = await api.setUserFollow(userid, !isFollowed);
      if (response.success) {
        setIsFollowed(!isFollowed);
        setUserData(prevData => {
          if (prevData) {
            const newData = {
              ...prevData,
              userInfo: {
                ...prevData.userInfo,
                isFollowed: !isFollowed,
                fansCount: isFollowed ? prevData.userInfo.fansCount - 1 : prevData.userInfo.fansCount + 1
              }
            };
            setCachedData(newData);
            return newData;
          }
          return prevData;
        });
        enqueueSnackbar(isFollowed ? "Successfully unfollowed" : "Successfully followed", {
          variant: "success",
          autoHideDuration: 3000
        });
      } else {
        throw new Error(response.msg || "Failed to toggle follow");
      }
    } catch (error) {
      console.error('Error toggling follow:', error);
      enqueueSnackbar("Oops! Something went wrong. Please try again later.", {
        variant: "error",
        autoHideDuration: 3000
      });
    } finally {
      setIsProcessing(false);
    }
  }, [userid, isFollowed, enqueueSnackbar, setCachedData]);

  const handleRefresh = useCallback(() => {
    fetchUserData(false);
  }, [fetchUserData]);

  if (!userData) {
    return (
      <div className="flex justify-center items-center h-screen">
        <SpinnerV size={40} />
      </div>
    );
  }

  const { userInfo, videos } = userData;

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto px-4 py-8"
    >
      <Card className="w-full max-w-3xl mx-auto bg-gradient-to-br from-purple-100 to-indigo-100">
        <CardHeader className="flex flex-col p-6 relative">
          <Button
            size="sm"
            variant="light"
            isIconOnly
            onClick={handleRefresh}
            disabled={isLoading}
            className="absolute top-2 right-2"
          >
            <Icon icon="mdi:refresh" className={isLoading ? "animate-spin" : ""} />
          </Button>
          <div className="flex flex-col sm:flex-row items-center w-full">
            <Avatar
              src={`https://robohash.org/${userid}?size=300x300&set=set5`}
              className="w-24 h-24 text-large"
              isBordered
              color="secondary"
            />
            <div className="ml-0 sm:ml-6 mt-4 sm:mt-0 text-center sm:text-left flex-grow">
              <h1 className="text-2xl font-bold">{userInfo.nickname}</h1>
              <p className="text-gray-500 mt-1">{userInfo.signature}</p>
              <div className="flex items-center justify-center sm:justify-start mt-2">
                <Tooltip content="Followers">
                  <div className="flex items-center mr-4">
                    <Icon icon="mdi:account-group" className="mr-1" />
                    <span>{userInfo.fansCount}</span>
                  </div>
                </Tooltip>
                <Button 
                  size="sm" 
                  color={isFollowed ? "secondary" : "primary"}
                  variant={isFollowed ? "bordered" : "solid"}
                  startContent={isProcessing ? <SpinnerV size={20} /> : <Icon icon={isFollowed ? "mdi:account-check" : "mdi:account-plus"} />}
                  onClick={handleFollowToggle}
                  disabled={isProcessing}
                >
                  {isProcessing ? "" : (isFollowed ? "Unfollow" : "Follow")}
                </Button>
              </div>
            </div>
          </div>
        </CardHeader>
        <Divider />
        <CardBody className="p-6">
          <h2 className="text-xl font-semibold mb-4">Videos</h2>
          {videos.length > 0 ? (
            <VideoList videos={videos} onVideoClick={handleVideoClick} />
          ) : (
            <p className="text-center text-gray-500">No videos yet</p>
          )}
        </CardBody>
      </Card>
    </motion.div>
  );
};

export default UserProfile;
