import React, {Key} from "react";
import {cn} from "../utils/cn";
import VideoListItem from "./VideoListItem";
import { Room } from "../services/api";

export interface Video {
    id: Key | null | undefined;
    thumbnail: string | undefined;
    videoid: string;
    timestamp: number;
    price: number;
    duration: number;
    view: number;
    comment: number;
    tags: string[];
    is_up: boolean;
    author: string;
    note: string;
    title: string;
    metamask: string;
    bywho: string;
    playlist: {
        scale: string;
        url: string;
        type: string;
    }[];
    winner_videoid: string;
    winner_original_videoid: string;
    is_followed: boolean;
    address: string;
    roomid?: Room[];
}

interface VideoListProps {
    videos: Video[];
    className?: string;
    onVideoClick: (videoId: string) => void;
}

const VideoList: React.FC<VideoListProps> = ({videos, className, onVideoClick}) => {
    return (
        <div className="my-auto flex h-full w-full max-w-7xl flex-col gap-2">
            <div
                className={cn(
                    "grid w-full grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3",
                    className,
                )}
            >
                {videos.map((video) => (
                    <VideoListItem
                        key={video.videoid}
                        {...video}
                        onVideoClick={() => onVideoClick(video.videoid)}
                    />
                ))}
            </div>
        </div>
    );
};

export default VideoList;
