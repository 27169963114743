import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import Web3 from 'web3';

export interface SplitterOption {
    value: string;
    label: string;
}

interface SplitterProps {
    splitterOptions: SplitterOption[];
    setSplitterOptions: React.Dispatch<React.SetStateAction<SplitterOption[]>>;
}

const Splitter: React.FC<SplitterProps> = ({ splitterOptions, setSplitterOptions }) => {
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState<string | null>(null);

    const validateAddress = (address: string): boolean => {
        const web3 = new Web3();
        return web3.utils.isAddress(address);
    };

    const isDuplicateAddress = (address: string): boolean => {
        return splitterOptions.some(option => option.value.toLowerCase() === address.toLowerCase());
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                if (inputValue.startsWith('0x') && validateAddress(inputValue)) {
                    if (isDuplicateAddress(inputValue)) {
                        setError('This address has already been added.');
                    } else {
                        const newOption = { value: inputValue, label: inputValue };
                        setSplitterOptions((prev) => [...prev, newOption]);
                        setInputValue('');
                        setError(null);
                    }
                } else {
                    setError('Invalid wallet address. Please enter a valid address starting with 0x.');
                }
                event.preventDefault();
        }
    };

    const handleInputChange = (newValue: string) => {
        setInputValue(newValue);
        setError(null);
    };

    const formatOptionLabel = ({ value }: SplitterOption) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {value}
        </div>
    );

    return (
        <div className="mb-4">
            <label className="block mb-2 font-bold">Splitter</label>
            <CreatableSelect
                isClearable
                isMulti
                menuIsOpen={false}
                components={{ DropdownIndicator: null }}
                inputValue={inputValue}
                value={splitterOptions}
                onChange={(newValue) => setSplitterOptions(newValue as SplitterOption[])}
                onInputChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Enter unique Web3 address starting with 0x and press Enter"
                formatOptionLabel={formatOptionLabel}
                styles={{
                    control: (base) => ({
                        ...base,
                        paddingTop: '0.5rem',
                        paddingBottom: '0.5rem',
                    }),
                    multiValue: (base) => ({
                        ...base,
                        marginBottom: '0.5rem',
                        maxWidth: '100%',
                    }),
                    multiValueLabel: (base) => ({
                        ...base,
                        fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }),
                    input: (base) => ({
                        ...base,
                        fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
                    }),
                }}
            />
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
    );
};

export default Splitter;