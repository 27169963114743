import React, {useEffect, useRef, useState, useCallback} from 'react';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';
import 'video.js/dist/video-js.css';
import {
    Button,
    Avatar,
    Tabs,
    Tab,
    Spinner,
    Tooltip,
    CardBody,
    Card,
    Input,
    NextUIProvider,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Slider
} from "@nextui-org/react";
import {Icon} from "@iconify/react";
import {api, ApiResponse} from '../services/api';
import {Video} from './VideoList';
import {formatDistanceToNow} from 'date-fns';
import {enUS} from "date-fns/locale";
import {SnackbarProvider, useSnackbar} from 'notistack';
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {polygon} from 'wagmi/chains'
import {ethers} from "ethers";
import SpinnerV from "./SpinnerV";
import { Link, useNavigate } from 'react-router-dom';
import { contractVABI } from "../services/contractVABI";
import { contractBountyABI } from "../services/contractBountyABI";

// todo 0.7

const addrVSmartContract = '0x6AD075b721fEE8d56eCF2F7871De09771930AC12';
const addrBountySmartContract = '0x66a4178ea1E870D1Be3296df91324cE593AC5001'

type BountyType = 'lollipop' | 'egg';

interface VideoDetailOverlayProps {
    videoId: string;
    userId: string;
    onClose: () => void;
}

interface CustomControlBar {
    el: () => HTMLElement;
    addChild: (name: string, options?: Record<string, unknown>) => void;
}

interface CustomPlayer extends Player {
    controlBar: CustomControlBar;
}

interface Comment {
    id: number;
    videoid: string;
    msguid: string;
    replyguid: string;
    bywho: string;
    msgtype: string;
    text: string;
    timestamp: number;
    metamask: string;
    ups: number;
    downs: number;
    reply?: Comment[];
}

interface ContractVideoDetails {
    v?: {
        address: string;
        tokenId: number;
        price: string;
        bountyHolders: string[];
        blockNumber: number;
        totalUnlock: number;
        totalLollipop: number;
        totalEgg: number;
    } | null;
    bounty?: {
        address: string;
        nftId: number;
        winner: string;
        bonus: number;
        desc: string;
    } | null;
}

const ContractDataDisplay: React.FC<{ 
    contractData: ContractVideoDetails, 
    videoInfo: Video, 
    userId: string,
    showChallengeButton: boolean,
    showSetWinner: boolean,
    onChallenge: () => void,
    onIncreaseBonus: () => void,
    onSetWinner: () => void
}> = ({
    contractData,
    videoInfo,
    showChallengeButton,
    onChallenge,
    onIncreaseBonus,
}) => {
    const contractV = contractData.v
    const contractBounty = contractData.bounty
    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            console.log('Copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    const openVideoInNewTab = () => {
        window.open(`/video/${videoInfo.winner_videoid}`, '_blank');
    };

    const zeroAddress = "0x0000000000000000000000000000000000000000";
    const isChallengeCompleted = contractData.bounty && contractData.bounty.winner !== zeroAddress;

    // 辅助函数：格式化数值为一位小数
    const formatValue = (value: string | number | undefined): string => {
        if (value === undefined) return '0.0';
        const numValue = typeof value === 'string' ? parseFloat(value) : value;
        return numValue.toFixed(1);
    };

    return (
        <div>
            {contractV && Object.keys(contractV).length > 0 && (
                <div className="bg-white rounded-lg p-4 mt-4 border-2 border-purple-300">
                    <div className="flex items-center mb-2">
                        <Icon icon="token-branded:polygon-zkevm" className="mr-2 text-xl"/>
                        <h3 className="text-lg font-semibold text-gray-700">Video Details on Contract</h3>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        {contractV.address && (
                            <div className="col-span-2">
                                <p className="text-sm text-gray-600">Address</p>
                                <div className="flex items-center">
                                    <p className="font-mono text-tiny">{contractV.address}</p>
                                    <Tooltip content="Copy NFT address">
                                        <Button isIconOnly size="sm" variant="light"
                                                onPress={() => copyToClipboard(`https://polygonscan.com/address/${contractV.address}/${contractV.tokenId}`)}>
                                            <Icon icon="gravity-ui:copy-transparent" width={20}/>
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>
                        )}
                        {contractV.blockNumber && (
                            <div>
                                <p className="text-sm text-gray-600">Block Number</p>
                                <p className="font-medium">{contractV.blockNumber}</p>
                            </div>
                        )}
                        {contractV.tokenId !== undefined && (
                            <div>
                                <p className="text-sm text-gray-600">Token ID</p>
                                <p className="font-medium">{contractV.tokenId}</p>
                            </div>
                        )}
                        {contractV.price && (
                            <div>
                                <p className="text-sm text-gray-600">Price</p>
                                <p className="font-medium">{formatValue(contractV.price)} POL</p>
                            </div>
                        )}
                        {contractV.totalUnlock !== undefined && (
                            <div>
                                <p className="text-sm text-gray-600">Total Unlock</p>
                                <p className="font-medium">{formatValue(contractV.totalUnlock)} POL</p>
                            </div>
                        )}
                        {contractV.totalLollipop !== undefined && (
                            <div>
                                <p className="text-sm text-gray-600">Total Lollipop</p>
                                <p className="font-medium">{formatValue(contractV.totalLollipop)} POL</p>
                            </div>
                        )}
                        {contractV.totalEgg !== undefined && (
                            <div>
                                <p className="text-sm text-gray-600">Total Egg</p>
                                <p className="font-medium">{formatValue(contractV.totalEgg)} POL</p>
                            </div>
                        )}
                    </div>
                    {contractV.bountyHolders && contractV.bountyHolders.length > 0 && (
                        <div className="mt-2">
                            <p className="text-sm text-gray-600">Splitter Distribution Address</p>
                            <div className="flex flex-wrap gap-1 mt-1">
                                {contractV.bountyHolders.map((holder, index) => (
                                    <span key={index}
                                          className="bg-gray-200 text-purple-700 text-xs font-medium px-2.5 py-0.5 rounded">
                    {`${holder.slice(0, 7)}...${holder.slice(-5)}`}
                  </span>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}

            {contractBounty && Object.keys(contractBounty).length > 0 && (
                <div className="bg-white rounded-lg p-4 mt-4 border-2 border-purple-300">
                    <div className="flex items-center mb-2">
                        <Icon icon="token-branded:polygon-zkevm" className="mr-2 text-xl"/>
                        <h3 className="text-lg font-semibold text-gray-700">Icebreaker Details on Contract</h3>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        {contractBounty.address && (
                            <div className="col-span-2">
                                <p className="text-tiny text-gray-600">Address</p>
                                <div className="flex items-center">
                                    <p className="font-mono text-tiny">{contractBounty.address}</p>
                                    <Tooltip content="Copy NFT URL">
                                        <Button isIconOnly size="sm" variant="light"
                                                onPress={() => copyToClipboard(`https://polygonscan.com/nft/${contractBounty.address}/${contractBounty.nftId}`)}>
                                            <Icon icon="gravity-ui:copy-transparent" width={20}/>
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>
                        )}
                        {contractBounty.nftId !== undefined && (
                            <div>
                                <p className="text-sm text-gray-600">NFT</p>
                                <p className="font-medium">{contractBounty.nftId}</p>
                            </div>
                        )}
                        {contractBounty.bonus !== undefined && (
                            <div>
                                <p className="text-sm text-gray-600">Prize Pool</p>
                                <p className="font-medium">{formatValue(contractBounty.bonus)} POL</p>
                            </div>
                        )}
                        {contractBounty.winner && contractBounty.winner !== zeroAddress && (
                            <div>
                                <div className="flex items-center">
                                    <p className="font-mono text-tiny">{contractBounty.winner}</p>
                                    <Tooltip content="Watch the winning video">
                                        <Button
                                            isIconOnly
                                            size="sm"
                                            variant="light"
                                            onPress={openVideoInNewTab}
                                        >
                                            <Icon icon="fa6-solid:circle-play" width={20}/>
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>
                        )}
                        {contractBounty.desc && (
                            <div className="col-span-2">
                                <p className="text-sm text-gray-600">Description</p>
                                <p className="text-tiny font-medium">{contractBounty.desc}</p>
                            </div>
                        )}
                    </div>
                    {isChallengeCompleted ? (
                        <div className="mt-4 bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded" role="alert">
                            <p className="font-bold">Challenge Completed!</p>
                            <p>This challenge has been successfully completed and a winner has been chosen.</p>
                        </div>
                    ) : (
                        <div className="mt-4 flex flex-wrap gap-2">
                            {showChallengeButton && (
                                <Button
                                    color="primary"
                                    variant="flat"
                                    onPress={onChallenge}
                                    size="sm"
                                >
                                    Challenge
                                </Button>
                            )}
                            <Button
                                color="secondary"
                                variant="flat"
                                onPress={onIncreaseBonus}
                                size="sm"
                            >
                                Increase Bonus
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const VideoDetailOverlayContent: React.FC<VideoDetailOverlayProps> = ({
    videoId,
    userId,
    onClose,
}) => {
    const {enqueueSnackbar} = useSnackbar();
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const playerRef = useRef<CustomPlayer | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [videoInfo, setVideoInfo] = useState<Video | null>(null);
    const [currentSource, setCurrentSource] = useState<number>(0);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const qualityButtonRef = useRef<HTMLButtonElement | null>(null);
    const [comments, setComments] = useState<Comment[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [contractData, setContractData] = useState<ContractVideoDetails | null>(null);
    const [newComment, setNewComment] = useState('');
    const [replyingTo, setReplyingTo] = useState<{ msguid: string, replyguid: string } | null>(null);
    const [replyText, setReplyText] = useState('');
    const replyInputRef = useRef<HTMLInputElement>(null);
    const [isSubmittingMainReply, setIsSubmittingMainReply] = useState(false);
    const [isSubmittingChildReply, setIsSubmittingChildReply] = useState(false);
    const [loadingStates, setLoadingStates] = useState<{
        [key: string]: { isLiking: boolean, isDisliking: boolean }
    }>({});
    const [isSendingBounty, setIsSendingBounty] = useState(false);
    const {login, user, sendTransaction} = usePrivy();
    const {wallets} = useWallets();

    const [likes, setLikes] = useState(0);
    const [dislikes, setDislikes] = useState(0);
    const [isFollowing, setIsFollowing] = useState(false);
    const [isFollowLoading, setIsFollowLoading] = useState(false);

    const showChallengeButton = contractData?.bounty && contractData.bounty.nftId;
    const showSetWinner = videoInfo?.author === userId;

    const [isIncreasingBonus, setIsIncreasingBonus] = useState(false);
    const [isSettingWinner, setIsSettingWinner] = useState(false);
    const [bonusAmount, setBonusAmount] = useState(0.1);
    const [winnerAddress, setWinnerAddress] = useState('');
    const [winnerVideoId, setWinnerVideoId] = useState<string | null>(null);
    const [pendingWinnerVideoId, setPendingWinnerVideoId] = useState<string | null>(null);
    const { isOpen: isIncreaseBonusModalOpen, onOpen: onIncreaseBonusModalOpen, onClose: onIncreaseBonusModalClose } = useDisclosure();
    const { isOpen: isSetWinnerModalOpen, onOpen: onSetWinnerModalOpen, onClose: onSetWinnerModalClose } = useDisclosure();

    const [challengeVideos, setChallengeVideos] = useState<Video[]>([]);

    const [activeTab, setActiveTab] = useState("comments");

    const navigate = useNavigate();

    const handleChallenge = () => {
        if (contractData?.bounty && contractData.bounty.nftId) {
            window.open(`/creation/nftid/${contractData.bounty.nftId}`, '_blank');
        } else {
            console.error('Challenge information is not available');
        }
    };

    const handleIncreaseBonus = async () => {
        setIsIncreasingBonus(true);
        try {
            const embeddedWallet = wallets.find(wallet => wallet.walletClientType === 'privy');
            if (!embeddedWallet) {
                throw new Error('Embedded wallet not found');
            }

            await embeddedWallet.switchChain(polygon.id);
            const provider = await embeddedWallet.getEthersProvider();

            const network = await provider.getNetwork();
            if (network.chainId !== polygon.id) {
                throw new Error(`Please switch to Polygon Mainnet, current is ${network.name} (${network.chainId})`);
            }

            const contract = new ethers.Contract(addrBountySmartContract, contractBountyABI, provider.getSigner());

            const bonusAmountWei = ethers.utils.parseEther(bonusAmount.toString());

            const gasEstimate = await contract.estimateGas.increaseBonus(videoId, { value: bonusAmountWei });

            const tx = await contract.increaseBonus(videoId, {
                value: bonusAmountWei,
                gasLimit: gasEstimate.mul(120).div(100)
            });

            console.log('Increase Bonus transaction sent:', tx.hash);
            
            const receipt = await tx.wait();
            console.log('Increase Bonus transaction confirmed:', receipt.transactionHash);

            enqueueSnackbar('Bonus increased successfully', { variant: 'success' });
            onIncreaseBonusModalClose();
            // 刷新页面
            navigate(0);
        } catch (error) {
            console.error('Error increasing bonus:', error);
            enqueueSnackbar(error instanceof Error ? error.message : 'An error occurred while increasing bonus', { variant: 'error' });
        } finally {
            setIsIncreasingBonus(false);
        }
    };

    const handleSetWinner = (winnerVideoId: string, winnerAddress: string) => {
        setPendingWinnerVideoId(winnerVideoId);
        setWinnerAddress(winnerAddress);
        onSetWinnerModalOpen();
    };

    const confirmSetWinner = async () => {
        if (!pendingWinnerVideoId) return;
        setIsSettingWinner(true);
        try {
            // Get the embedded wallet
            const embeddedWallet = wallets.find(wallet => wallet.walletClientType === 'privy');
            if (!embeddedWallet) {
                throw new Error('Embedded wallet not found');
            }

            await embeddedWallet.switchChain(polygon.id);
            const provider = await embeddedWallet.getEthersProvider();

            // 检查当前网络
            const network = await provider.getNetwork();
            if (network.chainId !== polygon.id) {
                throw new Error(`Please switch to Polygon Mainnet, current is ${network.name} (${network.chainId})`);
            }

            const contract = new ethers.Contract(addrBountySmartContract, contractBountyABI, provider.getSigner());

            console.log('Setting winner:', videoId, winnerAddress);

            // Estimate gas for the setWinner function
            const gasEstimate = await contract.estimateGas.setWinner(videoId, winnerAddress);

            // Call setWinner function on the smart contract
            const tx = await contract.setWinner(videoId, winnerAddress, {
                gasLimit: gasEstimate.mul(120).div(100) // Adding 20% buffer to gas estimate
            });

            console.log('Set Winner transaction sent:', tx.hash);
            
            // Wait for the transaction to be mined
            const receipt = await tx.wait();
            console.log('Set Winner transaction confirmed:', receipt.transactionHash);

            // Call the API to update the winner status
            const apiResponse = await api.setVideoWinner(videoId, pendingWinnerVideoId);
            if (!apiResponse.success) {
                throw new Error(apiResponse.msg || 'Failed to set winner in API');
            }

            // If successful, update the actual winner
            setWinnerVideoId(pendingWinnerVideoId);
            enqueueSnackbar('Winner set successfully', { variant: 'success' });
            onSetWinnerModalClose();
            // Refresh the page
            navigate(0);
        } catch (error) {
            console.error('Error setting winner:', error);
            if (error instanceof Error) {
                if (error.message.includes('User rejected')) {
                    enqueueSnackbar('The transaction was canceled by the user', { variant: 'warning' });
                } else {
                    enqueueSnackbar(error.message, { variant: 'error' });
                }
            } else {
                enqueueSnackbar('An unknown error occurred while setting winner', { variant: 'error' });
            }
        } finally {
            setIsSettingWinner(false);
            setPendingWinnerVideoId(null);
        }
    };

    const handleLikeDislike = async (comment: Comment, isLike: boolean, parentId?: number) => {
        const sign = isLike ? 1 : -1;
        const originalComments = [...comments];

        // Optimistically update the UI
        setComments(prevComments =>
            updateCommentCounts(prevComments, comment.id, isLike, parentId)
        );

        // Update loading state
        setLoadingStates(prev => ({
            ...prev,
            [comment.id]: {
                ...prev[comment.id],
                [isLike ? 'isLiking' : 'isDisliking']: true
            }
        }));

        try {
            const response = await api.setReplyLikeOrDislike(userId, videoId, comment.replyguid, sign);
            if (!response.success) {
                console.error('Failed to update like/dislike:', response.msg);
                // Revert the optimistic update
                setComments(originalComments);
            }
        } catch (error) {
            console.error('Error updating like/dislike:', error);
            // Revert the optimistic update
            setComments(originalComments);
        } finally {
            // Reset loading state
            setLoadingStates(prev => ({
                ...prev,
                [comment.id]: {
                    ...prev[comment.id],
                    [isLike ? 'isLiking' : 'isDisliking']: false
                }
            }));
        }
    };

    const updateCommentCounts = (comments: Comment[], commentId: number, isLike: boolean, parentId?: number): Comment[] => {
        return comments.map(c => {
            if (c.id === commentId) {
                return {
                    ...c,
                    ups: isLike ? c.ups + 1 : c.ups,
                    downs: !isLike ? c.downs + 1 : c.downs
                };
            } else if (c.id === parentId) {
                return {
                    ...c,
                    reply: c.reply ? updateCommentCounts(c.reply, commentId, isLike) : c.reply
                };
            } else if (c.reply) {
                return {
                    ...c,
                    reply: updateCommentCounts(c.reply, commentId, isLike)
                };
            }
            return c;
        });
    };

    const handleMainCommentSubmit = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && newComment.trim() !== '' && !isSubmittingMainReply) {
            setIsSubmittingMainReply(true);
            try {
                const response = await api.setReplyToVideo(userId, videoId, newComment.trim());
                if (response.success) {
                    // Optimistically add the new comment to the list
                    const newCommentObj: Comment = {
                        id: Date.now(),
                        videoid: videoId,
                        msguid: Date.now().toString(),
                        replyguid: Date.now().toString(),
                        bywho: userId,
                        msgtype: "msg",
                        text: newComment.trim(),
                        timestamp: Math.floor(Date.now() / 1000),
                        metamask: "",
                        ups: 0,
                        downs: 0,
                        reply: []
                    };
                    setComments(prevComments => [newCommentObj, ...prevComments]);
                    setNewComment('');
                } else {
                    console.error('Failed to submit comment:', response.msg);
                }
            } catch (error) {
                console.error('Error submitting comment:', error);
            } finally {
                setIsSubmittingMainReply(false);
            }
        }
    };

    const handleReplyClick = (msguid: string, replyguid: string) => {
        if (replyingTo && replyingTo.msguid === msguid) {
            setReplyingTo(null);
            setReplyText('');
        } else {
            setReplyingTo({msguid, replyguid});
            setReplyText('');
        }
    };

    const handleReplySubmit = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && replyText.trim() !== '' && !isSubmittingChildReply && replyingTo) {
            setIsSubmittingChildReply(true);
            try {
                const response = await api.setReplyToReply(userId, videoId, replyText.trim(), replyingTo.msguid, replyingTo.replyguid);
                if (response.success) {
                    const newReply: Comment = {
                        id: Date.now(),
                        videoid: videoId,
                        msguid: replyingTo.msguid,
                        replyguid: Date.now().toString(),
                        bywho: userId,
                        msgtype: "reply",
                        text: replyText.trim(),
                        timestamp: Math.floor(Date.now() / 1000),
                        metamask: "",
                        ups: 0,
                        downs: 0
                    };
                    setComments(prevComments =>
                        prevComments.map(comment =>
                            comment.msguid === replyingTo.msguid
                                ? {...comment, reply: [newReply, ...(comment.reply || [])]}
                                : comment
                        )
                    );
                    setReplyText('');
                    setReplyingTo(null);
                } else {
                    console.error('Failed to submit reply:', response.msg);
                }
            } catch (error) {
                console.error('Error submitting reply:', error);
            } finally {
                setIsSubmittingChildReply(false);
            }
        }
    };

    const renderCommentInput = () => (
        <div className="sticky top-0 bg-white z-10 border-b pb-4 p-2">
            <Input
                fullWidth
                placeholder="Add a comment..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                autoComplete="off"
                onKeyPress={handleMainCommentSubmit}
                disabled={isSubmittingMainReply}
                classNames={{
                    input: "pb-0",
                    inputWrapper: "pb-0 shadow-none",
                }}
                endContent={
                    isSubmittingMainReply && (
                        <Spinner
                            size="sm"
                            color="current"
                            className="mr-2"
                        />
                    )
                }
            />
        </div>
    );

    const handlePlayerResize = useCallback(() => {
        if (playerRef.current && containerRef.current) {
            const player = playerRef.current;
            const container = containerRef.current;
            const containerWidth = Math.min(container.clientWidth, 1440);
            const containerHeight = container.clientHeight;

            const aspectRatio = 16 / 9;

            let playerWidth = containerWidth;
            let playerHeight = containerWidth / aspectRatio;

            if (playerHeight > containerHeight) {
                playerHeight = containerHeight;
                playerWidth = containerHeight * aspectRatio;
            }

            playerWidth = Math.floor(playerWidth);
            playerHeight = Math.floor(playerHeight);

            player.width(playerWidth);
            player.height(playerHeight);

            const leftMargin = Math.max(0, Math.floor((containerWidth - playerWidth) / 2));

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            player.el().style.marginLeft = `${leftMargin}px`;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            player.el().style.marginTop = '0';
        }
    }, []);

    const getInitialSourceIndex = useCallback((playlist: Video['playlist']) => {
        return playlist.length > 1 ? 1 : 0;
    }, []);

    const updateQualityButtonText = useCallback(() => {
        if (qualityButtonRef.current && videoInfo) {
            qualityButtonRef.current.innerHTML = `${videoInfo.playlist[currentSource].scale}`;
        }
    }, [currentSource, videoInfo]);

    const createQualityButton = (initialScale: string) => {
        const qualityButton = document.createElement('button');
        qualityButton.className = 'vjs-quality-button vjs-control vjs-button';
        qualityButton.style.position = 'relative';
        qualityButton.innerHTML = `${initialScale}`;
        qualityButton.onclick = handleQualityButtonClick;
        return qualityButton;
    };

    const insertQualityButton = (controlBar: CustomControlBar, qualityButton: HTMLButtonElement) => {
        const fullscreenButton = controlBar.el().querySelector('.vjs-fullscreen-control');
        if (fullscreenButton) {
            controlBar.el().insertBefore(qualityButton, fullscreenButton);
        } else {
            controlBar.el().appendChild(qualityButton);
        }
    };

    const handleQualityButtonClick = (e: Event) => {
        e.stopPropagation();
        if (menuRef.current) {
            menuRef.current.remove();
            menuRef.current = null;
        } else {
            const menu = createQualityMenu();
            const button = e.currentTarget as HTMLElement;
            button.appendChild(menu);
            menuRef.current = menu;
        }
    };

    const createQualityMenu = () => {
        const menu = document.createElement('div');
        Object.assign(menu.style, {
            position: 'absolute',
            bottom: '100%',
            right: '0',
            backgroundColor: 'rgba(43, 51, 63, 0.7)',
            borderRadius: '2px',
            padding: '5px 0',
            zIndex: '1'
        });

        videoInfo?.playlist.forEach((source, index) => {
            const item = createMenuItem(source, index);
            menu.appendChild(item);
        });

        return menu;
    };

    const createMenuItem = (source: Video['playlist'][number], index: number) => {
        const item = document.createElement('div');
        item.innerText = source.scale;
        Object.assign(item.style, {
            color: '#fff',
            padding: '5px 15px',
            cursor: 'pointer'
        });
        item.addEventListener('mouseover', () => {
            item.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
        });
        item.addEventListener('mouseout', () => {
            item.style.backgroundColor = 'transparent';
        });
        item.onclick = (e) => handleMenuItemClick(e, index);
        return item;
    };

    const handleMenuItemClick = (e: Event, index: number) => {
        e.stopPropagation();
        handleSourceChange(index);
        if (menuRef.current) {
            menuRef.current.remove();
            menuRef.current = null;
        }
    };

    const handleOutsideClick = (e: Event) => {
        if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
            menuRef.current.remove();
            menuRef.current = null;
        }
    };

    const attemptAutoplay = useCallback((player: CustomPlayer) => {
        if (player.play) {
            player.muted(false);
            const playPromise = player.play();

            if (playPromise !== undefined) {
                playPromise.then(() => {
                    console.log('Autoplay started with sound');
                }).catch(error => {
                    console.warn('Autoplay with sound was prevented:', error);
                    const playButton = player.el().querySelector('.vjs-big-play-button');
                    if (playButton) {
                        (playButton as HTMLElement).style.display = 'block';
                    }
                });
            }
        } else {
            console.error('Play method is not available on the player');
        }
    }, [enqueueSnackbar]);

    const handlePlayerError = () => {
        const error = playerRef.current?.error();
        if (error) {
            console.error('Video.js error:', error.code, error.message);
        } else {
            console.error('An unknown video error occurred');
        }
    };

    const handleSourceChange = useCallback((sourceIndex: number) => {
        if (playerRef.current && videoInfo) {
            const currentTime = playerRef.current.currentTime();
            const isPlaying = !playerRef.current.paused();

            setCurrentSource(sourceIndex);
            playerRef.current.src({
                type: videoInfo.playlist[sourceIndex].type,
                src: videoInfo.playlist[sourceIndex].url
            });

            playerRef.current.one('loadedmetadata', () => {
                if (playerRef.current) {
                    playerRef.current.currentTime(currentTime);
                    if (isPlaying) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        playerRef.current.play().catch(error => {
                            console.error('Error resuming playback:', error);
                        });
                    }
                }
            });
        }
        updateQualityButtonText();
    }, [videoInfo, updateQualityButtonText]);

    const initializePlayer = useCallback(() => {
        if (playerRef.current || !videoRef.current || !videoInfo) {
            return;
        }

        const initialSourceIndex = getInitialSourceIndex(videoInfo.playlist);
        setCurrentSource(initialSourceIndex);

        const videoJsOptions = {
            controls: true,
            fluid: true,
            autoplay: true,
            muted: false,
            sources: [videoInfo.playlist[initialSourceIndex]].map(item => ({
                src: item.url,
                type: item.type
            })),
            controlBar: {
                pictureInPictureToggle: false
            },
            // 添加以下选项
            bigPlayButton: true,
            loadingSpinner: true,
        };

        const player = videojs(videoRef.current, videoJsOptions) as CustomPlayer;
        playerRef.current = player;

        player.ready(() => {
            const controlBar = player.controlBar;
            if (controlBar) {
                const qualityButton = createQualityButton(videoInfo.playlist[initialSourceIndex].scale);
                qualityButtonRef.current = qualityButton;
                insertQualityButton(controlBar, qualityButton);
                updateQualityButtonText();
            }

            player.on('click', handleOutsideClick);
            attemptAutoplay(player);
            handlePlayerResize();

            // 添加以事件监听器
            player.on('play', () => {
                const bigPlayButton = player.el().querySelector('.vjs-big-play-button');
                if (bigPlayButton) {
                    (bigPlayButton as HTMLElement).style.display = 'none';
                }
            });

            player.on('pause', () => {
                const bigPlayButton = player.el().querySelector('.vjs-big-play-button');
                if (bigPlayButton) {
                    (bigPlayButton as HTMLElement).style.display = 'block';
                }
            });
        });

        player.on('error', handlePlayerError);

        return () => {
            if (playerRef.current) {
                playerRef.current.dispose();
                playerRef.current = null;
            }
        };
    }, [videoInfo, getInitialSourceIndex, updateQualityButtonText, handlePlayerResize, attemptAutoplay]);

    const formatTimestamp = (timestamp: number) => {
        return formatDistanceToNow(new Date(timestamp * 1000), {addSuffix: true, locale: enUS});
    };

    const renderTags = () => {
        const tags = videoInfo?.tags;

        if (Array.isArray(tags) && tags.every(item => typeof item === 'string')) {
            return tags.map((tag, index) => (
                <span key={index} className="bg-gray-200 rounded-full px-2 py-1 text-xs mr-2 mb-2 inline-block">
                    {tag}
                </span>
            ));
        }

        console.warn('Unexpected tags type:', typeof tags);
        return null;
    };

    const renderComment = (comment: Comment, isReply = false, parentId?: number) => {
        const loadingState = loadingStates[comment.id] || {isLiking: false, isDisliking: false};

        const handleLikeClick = () => {
            if (loadingState.isLiking) return;
            handleLikeDislike(comment, true, parentId);
        };

        const handleDislikeClick = () => {
            if (loadingState.isDisliking) return;
            handleLikeDislike(comment, false, parentId);
        };

        return (
            <div key={comment.id} className={`mb-4 ${isReply ? 'ml-8 mt-2' : ''}`}>
                <div className="flex items-start mb-2">
                    <Link to={`/user/${comment.bywho}`} target="_blank" rel="noopener noreferrer">
                        <Avatar
                            src={`https://robohash.org/${comment.bywho}?size=300x300&set=set5`}
                            size="sm"
                            className="mr-2 w-11 h-7 min-w-[1.75rem] min-h-[1.75rem] max-w-[1.75rem] max-h-[1.75rem]"
                        />
                    </Link>
                    <div className="flex-grow">
                        <div className="flex justify-between items-center mb-1">
                            <span className="text-xs text-gray-500">{formatTimestamp(comment.timestamp)}</span>
                        </div>
                        <p className="text-sm text-gray-700 mb-2">{comment.text}</p>
                        <div className="flex items-center text-xs text-gray-600">
                            <Button
                                isIconOnly
                                variant="light"
                                size="sm"
                                className="mr-1"
                                onClick={handleLikeClick}
                                isLoading={loadingState.isLiking}
                            >
                                <Icon icon="mdi:thumb-up" width={14}/>
                            </Button>
                            <span className="mr-3">{comment.ups}</span>
                            <Button
                                isIconOnly
                                variant="light"
                                size="sm"
                                className="mr-1"
                                onClick={handleDislikeClick}
                                isLoading={loadingState.isDisliking}
                            >
                                <Icon icon="mdi:thumb-down" width={14}/>
                            </Button>
                            <span className="mr-3">{comment.downs}</span>
                            {!isReply && (
                                <Button
                                    variant="light"
                                    size="sm"
                                    className="text-xs"
                                    onClick={() => handleReplyClick(comment.msguid, comment.replyguid)}
                                >
                                    Reply
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
                {!isReply && replyingTo && replyingTo.msguid === comment.msguid && (
                    <div className="mt-2 ml-8">
                        <Input
                            ref={replyInputRef}
                            fullWidth
                            size="sm"
                            placeholder="Press Enter to reply"
                            autoComplete="off"
                            value={replyText}
                            onChange={(e) => setReplyText(e.target.value)}
                            onKeyPress={handleReplySubmit}
                            disabled={isSubmittingChildReply}
                            endContent={
                                isSubmittingChildReply && (
                                    <Spinner
                                        size="sm"
                                        color="current"
                                        className="mr-2"
                                    />
                                )
                            }
                        />
                    </div>
                )}
                {comment.reply && comment.reply.length > 0 && (
                    <div className="pl-4 border-l border-gray-200">
                        {comment.reply.map(replyComment => renderComment(replyComment, true, comment.id))}
                    </div>
                )}
            </div>
        );
    };

    const renderLikesAndDislikes = () => {
        return (
            <div className="flex items-center text-xs text-gray-600">
                <div className="flex items-center mr-3">
                    <span role="img" aria-label="view" className="mr-1">👀</span>
                    <span>{videoInfo?.view}</span>
                </div>
                <div className="flex items-center mr-3">
                    <Tooltip content="Send Lollipop">
                        <Button
                            isIconOnly
                            size="sm"
                            variant="light"
                            onClick={() => handleBountyCalling(videoId, 'lollipop')}
                            isLoading={isSendingBounty}
                            className="mr-1"
                        >
                            <span role="img" aria-label="lollipop">🍭</span>
                        </Button>
                    </Tooltip>
                    <span>{likes}</span>
                </div>
                <div className="flex items-center">
                    <Tooltip content="Send Egg">
                        <Button
                            isIconOnly
                            size="sm"
                            variant="light"
                            onClick={() => handleBountyCalling(videoId, 'egg')}
                            isLoading={isSendingBounty}
                            className="mr-1"
                        >
                            <span role="img" aria-label="egg">🥚</span>
                        </Button>
                    </Tooltip>
                    <span>{dislikes}</span>
                </div>
            </div>
        );
    };

    const callNotification = (message: string, success: boolean) => {
        enqueueSnackbar(message, {
            variant: success ? 'success' : 'warning',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            },
            autoHideDuration: 2000,
        });
    }

    const handleBountyCalling = async (videoId: string, bountyType: BountyType) => {
        setIsSendingBounty(true)
        console.log('handleBountyCalling', videoId, bountyType);

        try {
            if (!user) {
                console.log('User not authenticated.');
                login();
                return;
            }

            // Get the embedded wallet
            const embeddedWallet = wallets.find(wallet => wallet.walletClientType === 'privy');
            if (!embeddedWallet) {
                console.log('Embedded wallet not found');
                return;
            }

            const address = embeddedWallet.address;
            console.log('Using embedded wallet with address:', address);

            await embeddedWallet.switchChain(polygon.id);

            const provider = await embeddedWallet.getEthersProvider();

            // 检查当前网络
            const network = await provider.getNetwork();
            if (network.chainId !== polygon.id) {
                console.error('Not connected to Polygon Mainnet. Please switch networks.');
                callNotification(`Please switch to Polygon Mainnet, current is ${network.name} (${network.chainId})`, false)
                return;
            }

            // Get the POL balance using the embedded wallet provider
            const balance = await provider.getBalance(address);
            const balanceInMatic = ethers.utils.formatEther(balance);
            console.log('POL balance on Polygon:', balanceInMatic);

            // Prepare transaction data
            const bountyAmount = ethers.utils.parseUnits('0.1', 18);
            const data = new ethers.utils.Interface(contractVABI).encodeFunctionData('donate', [videoId, bountyType]);

            // Estimate gas
            const gasEstimate = await provider.estimateGas({
                to: addrVSmartContract,
                value: bountyAmount,
                data: data,
            });

            // Send transaction using Privy's embedded wallet
            callNotification('Pending ...', true)
            const transactionResponse = await sendTransaction({
                to: addrVSmartContract,
                value: bountyAmount.toHexString(),
                data: data,
                gasLimit: gasEstimate.toHexString(),
            });

            console.log('Transaction sent:', transactionResponse);
            callNotification('Bounty transaction submitted!', true)

            if (bountyType === 'lollipop') {
                setLikes(prevLikes => prevLikes + 1);
            } else if (bountyType === 'egg') {
                setDislikes(prevDislikes => prevDislikes + 1);
            }

        } catch (error) {
            console.error('Error sending bounty:', error);
            if (error instanceof Error) {
                if (error.message.includes('User rejected')) {
                    callNotification('The transaction was canceled by the user', false)
                } else if (error.message.includes('insufficient funds')) {
                    callNotification('Insufficient wallet funds', false)
                } else {
                    callNotification(error.message, false)
                }
            } else {
                callNotification('An unknown error', false)
            }
        } finally {
            setIsSendingBounty(false)
        }
    };

    const renderRelatedContent = () => {
        return (
            <div className="flex flex-col items-center justify-center h-full text-center p-4">
                <Icon icon="mdi:tools" className="text-gray-400 mb-2" width={48} height={48} />
                <p className="text-gray-600 mb-2">Related Videos Temporarily Unavailable</p>
                <p className="text-sm text-gray-500">We're working on improving this feature. Check back soon!</p>
            </div>
        );
    };

    const handleFollowToggle = async () => {
        if (!videoInfo) return;
        
        setIsFollowLoading(true);
        try {
            const response = await api.setUserFollow(videoInfo.author, !isFollowing);
            if (response.success) {
                setIsFollowing(!isFollowing);
                enqueueSnackbar(isFollowing ? 'Unfollowed successfully' : 'Followed successfully', { variant: 'success' });
            } else {
                enqueueSnackbar('Failed to update follow status', { variant: 'error' });
            }
        } catch (error) {
            console.error('Error updating follow status:', error);
            enqueueSnackbar('An error occurred while updating follow status', { variant: 'error' });
        } finally {
            setIsFollowLoading(false);
        }
    };

    useEffect(() => {
        if (replyingTo && replyInputRef.current) {
            replyInputRef.current.focus();
        }
    }, [replyingTo]);

    useEffect(() => {
        const fetchComments = async () => {
            try {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                const response: ApiResponse<{ list: Comment[] }> = await api.getComments(videoId, userId);
                if (response.success && response.data.list) {
                    setComments(response.data.list);
                } else {
                    console.error('Failed to fetch comments:', response.msg);
                }
            } catch (error) {
                console.error('Error fetching comments:', error);
            }
        };

        const fetchSmartContractDetails = async () => {
            try {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                const response: ApiResponse<ContractVideoDetails> = await api.getSmartContractVideoDetails(videoId);
                if (response.success) {
                    console.log('fetch SmartContractDetails', response.data);
                    setContractData(response.data);
                } else {
                    console.error('Failed to fetch SmartContractDetails:', response.msg);
                }
            } catch (error) {
                console.error('Error fetching contract data:', error);
            }
        }

        fetchComments();
        fetchSmartContractDetails();
    }, [videoId]);

    useEffect(() => {
        const fetchVideoInfo = async () => {
            setIsLoading(true);
            try {
                const response: ApiResponse<Video> = await api.getVideoInfo(videoId, userId);
                if (response.success) {
                    setVideoInfo(response.data);
                    setIsFollowing(response.data.is_followed);
                    setWinnerVideoId(response.data.winner_videoid || null);
                    const initialSourceIndex = getInitialSourceIndex(response.data.playlist);
                    setCurrentSource(initialSourceIndex);
                } else {
                    console.error('Failed to fetch video info:', response.msg);
                }
            } catch (error) {
                console.error('Error fetching video info:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchVideoInfo();

        return () => {
            if (playerRef.current) {
                playerRef.current.dispose();
                playerRef.current = null;
            }
        };
    }, [videoId, getInitialSourceIndex]);

    useEffect(() => {
        initializePlayer();
    }, [initializePlayer]);

    useEffect(() => {
        updateQualityButtonText();
    }, [currentSource, updateQualityButtonText]);

    useEffect(() => {
        window.addEventListener('resize', handlePlayerResize);
        return () => {
            window.removeEventListener('resize', handlePlayerResize);
        };
    }, [handlePlayerResize]);

    useEffect(() => {
        document.title = videoInfo?.title || 'V Watch Video';
        if (playerRef.current) {
            handlePlayerResize();
        }
    }, [handlePlayerResize, videoInfo]);

    useEffect(() => {
        if (contractData && contractData.v) {
            setLikes((contractData.v.totalLollipop ?? 0) * 10);
            setDislikes((contractData.v.totalEgg ?? 0) * 10);
        }
    }, [contractData]);

    useEffect(() => {
        const fetchChallengeVideos = async () => {
            if (contractData?.bounty && contractData.bounty.nftId) {
                try {
                    const response = await api.getChallengeVideos(contractData.bounty.nftId.toString());
                    if (response.success && Array.isArray(response.data)) {
                        setChallengeVideos(response.data);
                    } else {
                        console.error('Failed to fetch challenge videos:', response.msg);
                    }
                } catch (error) {
                    console.error('Error fetching challenge videos:', error);
                }
            }
        };

        fetchChallengeVideos();
    }, [contractData]);

    useEffect(() => {
        if (contractData?.bounty && contractData.bounty.nftId) {
            setActiveTab("challenge");
        }
    }, [contractData]);

    if (isLoading) {
        return (
            <div className="fixed inset-0 bg-gray-50 z-50 flex items-center justify-center">
                <SpinnerV />
            </div>
        );
    }

    const handleGoBack = () => {
        window.history.back();
    };

    if (!videoInfo) {
        return (
            <div className="fixed inset-0 bg-gray-50 z-50 flex items-center justify-center">
                <Card>
                    <CardBody className="flex flex-col items-center gap-4 py-6 px-8">
                        <div className="flex flex-row items-center gap-2">
                            <Icon
                                className="text-danger"
                                icon="fluent:error-circle-12-regular"
                                width={24}
                            />
                            <span className="text-lg font-semibold text-gray-700">
                                Failed to load video information
                            </span>
                        </div>
                        <Button color="primary" onClick={handleGoBack}>
                            Go Back
                        </Button>
                    </CardBody>
                </Card>
            </div>
        );
    }

    const openVideoInNewTab = (videoId: string) => {
        window.open(`/video/${videoId}`, '_blank');
    };

    const renderComments = () => {
        if (comments.length === 0) {
            return (
                <div className="flex flex-col items-center justify-center h-full text-center p-4">
                    <Icon icon="mdi:comment-outline" className="text-gray-400 mb-2" width={48} height={48} />
                    <p className="text-gray-600 mb-2">No comments yet</p>
                    <p className="text-sm text-gray-500">Be the first to share your thoughts!</p>
                </div>
            );
        }
        return comments.map(comment => renderComment(comment));
    };

    const renderChallengeVideos = () => {
        if (challengeVideos.length === 0) {
            return (
                <div className="flex flex-col items-center justify-center h-full text-center p-4">
                    <Icon icon="mdi:video-outline" className="text-gray-400 mb-2" width={48} height={48} />
                    <p className="text-gray-600 mb-2">No challenge videos yet</p>
                    <p className="text-sm text-gray-500">Be the first to take on this challenge!</p>
                </div>
            );
        }

        const isChallengeCompleted = !!winnerVideoId;

        return (
            <div className="space-y-2">
                {challengeVideos.map((video) => (
                    <div key={video.videoid} className={`flex items-start space-x-3 p-2 rounded-lg ${video.videoid === winnerVideoId ? 'bg-yellow-100' : 'hover:bg-gray-100'}`}>
                        <div className="flex-shrink-0 w-20 h-12 relative group">
                            <img
                                src={`https://file.v.watch/cover/${video.videoid}.jpg`}
                                alt={video.title}
                                className="object-cover w-full h-full rounded-lg"
                            />
                            <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                                <Button
                                    isIconOnly
                                    color="danger"
                                    variant="solid"
                                    onPress={() => openVideoInNewTab(video.videoid)}
                                    className="rounded-full p-1"
                                >
                                    <Icon icon="mdi:play" width={16} height={16} />
                                </Button>
                            </div>
                        </div>
                        <div className="flex-grow">
                            <div className="flex items-center mt-1">
                                <Link to={`/user/${video.author}`} target="_blank" rel="noopener noreferrer">
                                    <Avatar
                                        src={`https://robohash.org/${video.author}?size=300x300&set=set5`}
                                        size="sm"
                                        className="mr-2 w-5 h-5"
                                    />
                                </Link>
                                <h3 className="text-sm font-medium text-gray-900 truncate flex-grow">{video.title}</h3>
                                {video.videoid === winnerVideoId && (
                                    <Tooltip content="Winner">
                                        <span className="ml-2">
                                            <Icon icon="mdi:trophy" className="text-yellow-500" width={20} height={20} />
                                        </span>
                                    </Tooltip>
                                )}
                            </div>
                            <div className="flex items-center justify-between mt-1">
                                <p className="text-xs text-gray-500">
                                    {formatDistanceToNow(new Date(video.timestamp * 1000), { addSuffix: true })}
                                </p>
                                { showSetWinner && !isChallengeCompleted && (
                                    <Button
                                        size="sm"
                                        color="primary"
                                        variant="flat"
                                        onPress={() => handleSetWinner(video.videoid, video.address)}
                                        className="text-xs py-0.5 px-2"
                                    >
                                        Set as Winner
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="fixed inset-0 bg-white z-50 overflow-hidden flex flex-col">
            {/* Top navigation bar */}
            <div className="flex justify-between items-center p-2 border-b bg-white">
                <h2 className="text-xl font-semibold truncate">{videoInfo?.title}</h2>
                <Button isIconOnly onClick={onClose} variant="light" className="text-gray-600">
                    <Icon icon="mdi:close" width={24} />
                </Button>
            </div>

            {/* Main content area */}
            <div className="flex flex-col lg:flex-row flex-grow overflow-hidden">
                {/* Left video area */}
                <div className="w-full lg:w-3/4 h-full flex flex-col">
                    {/* Video player */}
                    <div ref={containerRef} className="w-full bg-black">
                        <video
                            ref={videoRef}
                            className="video-js vjs-big-play-centered w-full h-full"
                        />
                    </div>

                    {/* Video info and interaction area */}
                    <div className="p-4 overflow-y-auto">
                        <div className="flex flex-col mb-4">
                            <div className="flex items-center mb-2">
                                <Link to={`/user/${videoInfo?.author}`} target="_blank" rel="noopener noreferrer">
                                    <Avatar
                                        src={`https://robohash.org/${videoInfo?.author}?size=300x300&set=set5`}
                                        size="lg"
                                        className="mr-3 rounded-full"
                                    />
                                </Link>
                                <div className="flex flex-col">
                                    <h1 className="text-xl font-bold mb-1">{videoInfo?.title}</h1>
                                    {/* Room 和 Icebreaker 信息展示 */}
                                    <div className="flex items-center gap-1 mb-1">
                                        {videoInfo?.roomid && videoInfo.roomid.length > 0 && (
                                            <>
                                                <Icon icon="material-symbols-light:nest-multi-room-rounded" />
                                                {videoInfo.roomid.map((room) => (
                                                    <Link
                                                        key={room.id}
                                                        to={`/room/${room.id}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-sm text-primary-500 hover:text-primary-600 transition-colors"
                                                    >
                                                        {room.name}
                                                    </Link>
                                                ))}
                                            </>
                                        )}
                                        {videoInfo?.winner_original_videoid && (
                                            <>
                                                <Icon icon="bx:game" className="ml-1" />
                                                <Link
                                                    to={`/video/${videoInfo.winner_original_videoid}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-sm text-primary-500 hover:text-primary-600 transition-colors"
                                                >
                                                    Icebreaker
                                                </Link>
                                            </>
                                        )}
                                    </div>
                                    <p className="text-sm text-gray-600">
                                        {formatDistanceToNow(new Date(videoInfo?.timestamp * 1000), { addSuffix: true })}
                                    </p>
                                </div>
                            </div>
                            <p className="text-sm text-gray-700 mb-2">{videoInfo?.note}</p>
                            <div className="flex justify-between items-center">
                                {renderLikesAndDislikes()}
                                <div className="flex items-center">
                                    <Button
                                        color={isFollowing ? "default" : "primary"}
                                        variant={isFollowing ? "bordered" : "solid"}
                                        onPress={handleFollowToggle}
                                        isLoading={isFollowLoading}
                                        size="sm"
                                    >
                                        {isFollowing ? "Unfollow" : "Follow"}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {renderTags()}
                        {contractData && videoInfo && (
                            <ContractDataDisplay 
                                contractData={contractData} 
                                videoInfo={videoInfo} 
                                userId={userId}
                                showChallengeButton={!!showChallengeButton}
                                showSetWinner={showSetWinner}
                                onChallenge={handleChallenge}
                                onIncreaseBonus={onIncreaseBonusModalOpen}
                                onSetWinner={onSetWinnerModalOpen}
                            />
                        )}
                    </div>

                    {/* Divider for mobile view */}
                    <div className="lg:hidden w-full h-px bg-gray-200"></div>

                    {/* Mobile view tabs */}
                    <div className="lg:hidden w-full">
                        <Tabs 
                            aria-label="Video tabs" 
                            className="w-full"
                            selectedKey={activeTab}
                            onSelectionChange={(key) => setActiveTab(key as string)}
                            variant="underlined"
                            classNames={{
                                tabList: "w-full flex justify-center gap-4",
                                cursor: "w-full",
                                tab: "flex-1 max-w-[200px] px-0",
                            }}
                        >
                            <Tab key="comments" title="Comments">
                                <div className="h-[25vh] flex flex-col"> 
                                    {renderCommentInput()}
                                    <div className="flex-grow overflow-y-auto px-2 py-4">
                                        {renderComments()}
                                    </div>
                                </div>
                            </Tab>
                            {contractData?.bounty && contractData.bounty.nftId ? (
                                <Tab key="challenge" title="Challenge Videos">
                                    <div className="h-[25vh] overflow-y-auto px-2 py-4"> 
                                        {renderChallengeVideos()}
                                    </div>
                                </Tab>
                            ) : (
                                <Tab key="related" title="Related">
                                    <div className="h-[25vh] overflow-y-auto px-2 py-4"> 
                                        {renderRelatedContent()}
                                    </div>
                                </Tab>
                            )}
                        </Tabs>
                    </div>
                </div>

                {/* Right comments and related videos area (desktop only) */}
                <div className="hidden lg:flex lg:flex-col w-1/4 h-full border-l">
                    <Tabs 
                        aria-label="Video tabs" 
                        className="h-full flex flex-col"
                        selectedKey={activeTab}
                        onSelectionChange={(key) => setActiveTab(key as string)}
                    >
                        <Tab key="comments" title="Comments">
                            <div className="h-full flex flex-col">
                                {renderCommentInput()}
                                <div className="flex-grow overflow-y-auto p-4">
                                    {renderComments()}
                                </div>
                            </div>
                        </Tab>
                        {contractData?.bounty && contractData.bounty.nftId ? (
                            <Tab key="challenge" title="Challenge Videos">
                                <div className="h-full overflow-y-auto p-4">
                                    {renderChallengeVideos()}
                                </div>
                            </Tab>
                        ) : (
                            <Tab key="related" title="Related">
                                <div className="h-full overflow-y-auto p-4">
                                    {renderRelatedContent()}
                                </div>
                            </Tab>
                        )}
                    </Tabs>
                </div>
            </div>

            <Modal isOpen={isIncreaseBonusModalOpen} onClose={onIncreaseBonusModalClose}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Increase Bonus</ModalHeader>
                            <ModalBody>
                                <Slider
                                    label="Bonus Amount (POL)"
                                    step={0.1}
                                    maxValue={10}
                                    minValue={0.1}
                                    value={bonusAmount}
                                    onChange={(value) => setBonusAmount(Number(value))}
                                    className="max-w-md"
                                    isDisabled={isIncreasingBonus}
                                />
                                <p>Selected amount: {bonusAmount} POL</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose} isDisabled={isIncreasingBonus}>
                                    Cancel
                                </Button>
                                <Button color="primary" onPress={handleIncreaseBonus} isLoading={isIncreasingBonus}>
                                    Increase Bonus
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

            <Modal isOpen={isSetWinnerModalOpen} onClose={onSetWinnerModalClose}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Confirm Winner</ModalHeader>
                            <ModalBody>
                                <p>Are you sure you want to set the following video as the winner?</p>
                                <p className="font-semibold mt-2">{challengeVideos.find(v => v.videoid === pendingWinnerVideoId)?.title}</p>
                                <div className="mt-4 p-2 bg-yellow-50 border border-yellow-200 rounded-md">
                                    <p className="text-sm text-yellow-700">
                                        <Icon icon="mdi:alert-circle-outline" className="inline-block mr-1" />
                                        This action cannot be undone. The selected video will be permanently set as the winner.
                                    </p>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Cancel
                                </Button>
                                <Button color="primary" onPress={confirmSetWinner} isLoading={isSettingWinner}>
                                    Confirm Winner
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    );
};

const WithProviders: React.FC<{ children: React.ReactNode }> = ({children}) => (
    <NextUIProvider>
        <SnackbarProvider maxSnack={3}>
            {children}
        </SnackbarProvider>
    </NextUIProvider>
);

const VideoDetailOverlay: React.FC<VideoDetailOverlayProps> = (props) => (
    <WithProviders>
        <VideoDetailOverlayContent {...props} />
    </WithProviders>
);

export default VideoDetailOverlay;
