import React, {useState, useEffect, useRef} from "react";
import HorizontalSteps from "./HorizontalSteps";
import { api } from "../services/api";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { S3Client, PutObjectCommandInput } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import ImageCropper from './ImageCropper';
import {
    Input,
    Textarea,
    Switch,
    Slider,
    Modal,
    Button,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter, useDisclosure,
    Select,
    SelectItem
} from "@nextui-org/react";
import { MultiValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { v4 as uuidv4 } from 'uuid';
import Splitter, { SplitterOption } from "./Splitter";
import {FilePondFile} from "filepond";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {Icon} from "@iconify/react";
import { polygon } from 'wagmi/chains'
import {ethers} from "ethers";
import { useParams } from 'react-router-dom';
const addrVSmartContract = '0x6AD075b721fEE8d56eCF2F7871De09771930AC12';
const addrBountySmartContract = '0x66a4178ea1E870D1Be3296df91324cE593AC5001'
import { ApiResponse, RoomData, Room } from '../services/api';
import { contractVABI } from "../services/contractVABI";
import { contractBountyABI } from "../services/contractBountyABI";

// todo 0.7

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

interface TagOption {
    value: string;
    label: string;
}

const generateUUIDWithoutHyphens = (): string => {
    return uuidv4().replace(/-/g, '');
};

const stepContents = [
    { title: "Prepare Video", content: "Select and upload your video file" },
    { title: "Choose Cover", content: "Choose and crop a thumbnail for your video" },
    { title: "Basic Information", content: "Add title, description, and tags" },
    { title: "Advanced Settings", content: "Set price and revenue sharing" },
    { title: "Review & Publish", content: "Review details and publish your video" },
];

const MIN_WIDTH = 400;
const MIN_HEIGHT = 200;

const defaultTags: TagOption[] = [
    { value: 'Music', label: 'Music' },
    { value: 'ShortFile', label: 'ShortFile' },
    { value: 'Vlog', label: 'Vlog' },
    { value: 'Entertainment', label: 'Entertainment' },
    { value: 'Technology', label: 'Technology' },
    { value: 'Lifestyle', label: 'Lifestyle' },
];

export default function Component() {
    const { ready, login, authenticated } = usePrivy();
    const { wallets } = useWallets();
    const [isCheckingAuth, setIsCheckingAuth] = useState(true);
    const [currentStep, setCurrentStep] = useState(0);
    const [files, setFiles] = useState<FilePondFile[]>([]);
    const [sessionCreation, setSessionCreation] = useState<string | ''>('');
    const [isUploadComplete, setIsUploadComplete] = useState(false);
    const [showUploadWarning, setShowUploadWarning] = useState(false);
    const [step1Completed, setStep1Completed] = useState(false);
    const [coverImage, setCoverImage] = useState<string | null>(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
    const [imageSizeError, setImageSizeError] = useState<string | null>(null);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [tags, setTags] = useState<TagOption[]>([]);
    const [visibility, setVisibility] = useState(true);
    const [price, setPrice] = useState<number>(0);
    const [isStep3Valid, setIsStep3Valid] = useState(false);
    const userLoginData = JSON.parse(localStorage.getItem('userLoginData') ?? '{}');
    const filePondRef = useRef<FilePond | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [splitterOptions, setSplitterOptions] = useState<SplitterOption[]>([]);
    const [videoId, setVideoId] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { isOpen: isSuccessModalOpen, onOpen: onSuccessModalOpen, onClose: onSuccessModalClose } = useDisclosure();
    const [rooms, setRooms] = useState<Room[]>([]);
    const [selectedRoom, setSelectedRoom] = useState<string | null>(null);
    const { nftid } = useParams<{ nftid?: string }>();
    const [challengeNftId, setChallengeNftId] = useState<string | null>(null);
    const [createChallenge, setCreateChallenge] = useState(false);
    const [challengeBonus, setChallengeBonus] = useState(1);
    const [challengeDescription, setChallengeDescription] = useState("");
    const [isChallengeDescriptionValid, setIsChallengeDescriptionValid] = useState(false);

    const handleViewVideo = () => {
        if (videoId) {
            window.location.href = `/video/${videoId}`;
        }
        setShowSuccessModal(false);
    };

    const validateImageSize = (file: File): Promise<boolean> => {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
                if (img.width < MIN_WIDTH || img.height < MIN_HEIGHT) {
                    setImageSizeError(`Image dimensions must be at least ${MIN_WIDTH}x${MIN_HEIGHT} pixels. Uploaded image is ${img.width}x${img.height} pixels.`);
                    resolve(false);
                } else {
                    setImageSizeError(null);
                    resolve(true);
                }
            };
            img.src = URL.createObjectURL(file);
        });
    };

    const extractFirstFrame = async (file: File) => {
        if (file.type.startsWith('image/')) {
            const isValidSize = await validateImageSize(file);
            if (!isValidSize) {
                return;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
                setCoverImage(e.target?.result as string);
                setCroppedImageUrl(null);
            };
            reader.readAsDataURL(file);
        } else if (file.type.startsWith('video/')) {
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = () => {
                video.currentTime = 0;
            };
            video.onseeked = () => {
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                if (canvas.width < MIN_WIDTH || canvas.height < MIN_HEIGHT) {
                    setImageSizeError(`Video frame dimensions must be at least ${MIN_WIDTH}x${MIN_HEIGHT} pixels. Current frame is ${canvas.width}x${canvas.height} pixels.`);
                    return;
                }
                setImageSizeError(null);
                canvas.getContext('2d')?.drawImage(video, 0, 0);
                const dataUrl = canvas.toDataURL('image/jpeg');
                setCoverImage(dataUrl);
                setCroppedImageUrl(null);
            };
            video.src = URL.createObjectURL(file);
        }
    };

    const handleStepChange = (stepIndex: number) => {
        if (stepIndex <= currentStep && !(stepIndex === 0 && step1Completed)) {
            setCurrentStep(stepIndex);
        }
    };

    const handleNext = () => {
        if (currentStep === 0 && !isUploadComplete) {
            setShowUploadWarning(true);
            setTimeout(() => setShowUploadWarning(false), 3000);
            return;
        }
        if (currentStep === 1 && !croppedImageUrl) {
            alert("Please crop and save the cover image before proceeding.");
            return;
        }
        if (currentStep < stepContents.length - 1) {
            if (currentStep === 0) {
                setStep1Completed(true);
            }
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevious = () => {
        if (currentStep > 0 && !(currentStep === 1 && step1Completed)) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleTagChange = (
        newValue: MultiValue<TagOption>
    ) => {
        setTags(newValue as TagOption[]);
    };

    const getSessionAndUploadToS3 = async (file: File, load: (response: string) => void, error: (errorText: string) => void, progress: (percentage: number, chunk: number, total: number) => void, abort: () => void) => {
        try {
            const session_creation = await generateUUIDWithoutHyphens();
            setSessionCreation(session_creation);
            const apiResponse = await api.getSessionForUploading(userLoginData.userid, session_creation);

            if (!apiResponse.data || !apiResponse.data.videoid_new) {
                throw new Error('Invalid API response');
            }

            setVideoId(apiResponse.data.videoid_new);

            const s3Client = new S3Client({
                region: 'us-east-1',
                credentials: {
                    accessKeyId: apiResponse.data.access_key_id,
                    secretAccessKey: apiResponse.data.secret_access_key,
                    sessionToken: apiResponse.data.session_token,
                },
            });

            const key = `convert_input/${apiResponse.data.videoid_new}`;

            const params = {
                Bucket: 'vevue-us',
                Key: key,
                Body: file,
            };

            const uploader = new Upload({
                client: s3Client,
                params: params,
            });

            uploader.on('httpUploadProgress', (progressEvent) => {
                if (typeof progressEvent.loaded === 'number' && typeof progressEvent.total === 'number') {
                    const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    progress(percentage, progressEvent.loaded, progressEvent.total);
                }
            });

            await uploader.done();
            load('Upload completed');
            setIsUploadComplete(true);
        } catch (err: unknown) {
            if (err instanceof Error) {
                if (err.name === 'AbortError') {
                    abort();
                } else {
                    error('Upload failed: ' + err.message);
                }
            } else {
                error('Upload failed: An unknown error occurred');
            }
            setIsUploadComplete(false);
        }
    };

    const handleReupload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                const isValidSize = await validateImageSize(file);
                if (isValidSize) {
                    extractFirstFrame(file);
                }
            } else {
                extractFirstFrame(file);
            }
        }
    };

    const uploadCoverImageToS3 = async (imageBlob: Blob, videoid_new: string) => {
        try {
            const apiResponse = await api.getSessionForUploading(userLoginData.userid, await generateUUIDWithoutHyphens());

            if (!apiResponse.data || !apiResponse.data.videoid_new) {
                throw new Error('Invalid API response');
            }

            const s3Client = new S3Client({
                region: 'us-east-1',
                credentials: {
                    accessKeyId: apiResponse.data.access_key_id,
                    secretAccessKey: apiResponse.data.secret_access_key,
                    sessionToken: apiResponse.data.session_token,
                },
            });

            const key = `cover/${videoid_new}.jpg`;

            const params: PutObjectCommandInput = {
                Bucket: 'vevue-us',
                Key: key,
                Body: imageBlob,
                ContentType: 'image/jpeg',
                ACL: 'public-read'
            };

            const uploader = new Upload({
                client: s3Client,
                params: params,
            });

            await uploader.done();
            console.log('Cover image uploaded successfully');
        } catch (err: unknown) {
            if (err instanceof Error) {
                console.error('Cover image upload failed:', err.message);
            } else {
                console.error('Cover image upload: An unknown error occurred');
            }
            throw err;
        }
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        setLoadingStatus('Waiting for image uploading');

        try {
            if (!videoId) {
                throw new Error('Video ID not found');
            }

            if (!croppedImageUrl) {
                throw new Error('Cropped image not found');
            }

            if (!sessionCreation) {
                throw new Error('Session creation ID not found');
            }

            // Convert the cropped image URL to a Blob
            const response = await fetch(croppedImageUrl);
            const blob = await response.blob();

            // Upload the cover image
            await uploadCoverImageToS3(blob, videoId);

            setLoadingStatus('Waiting for NFT minting');

            // Get the Privy embedded wallet
            const embeddedWallet = wallets.find(wallet => wallet.walletClientType === 'privy');
            if (!embeddedWallet) {
                throw new Error('Embedded wallet not found');
            }

            // Get provider
            const provider = await embeddedWallet.getEthersProvider();

            // Check network
            await embeddedWallet.switchChain(polygon.id);
            const network = await provider.getNetwork();
            if (network.chainId !== polygon.id) {
                throw new Error(`Please switch to Polygon Mainnet, current is ${network.name} (${network.chainId})`);
            }

            // Create contract instance
            const contract = new ethers.Contract(addrVSmartContract, contractVABI, provider.getSigner());

            // Prepare transaction parameters
            const priceInWei = ethers.utils.parseUnits((price * 1e6).toFixed(0), 12);
            const bountyHolders = splitterOptions.length > 0 ? splitterOptions.map(item => item.value) : [embeddedWallet.address];

            // Estimate gas
            const gasEstimate = await contract.estimateGas.createNFT(videoId, bountyHolders, priceInWei);

            // Send transaction
            const tx = await contract.createNFT(videoId, bountyHolders, priceInWei, {
                gasLimit: gasEstimate.mul(120).div(100)
            });

            console.log('Transaction sent:', tx.hash);

            if (createChallenge) {
                setLoadingStatus('Creating Challenge');
                
                // 获取嵌入式钱包
                const embeddedWallet = wallets.find(wallet => wallet.walletClientType === 'privy');
                if (!embeddedWallet) {
                    throw new Error('Embedded wallet not found');
                }

                // 获取provider
                const provider = await embeddedWallet.getEthersProvider();

                // 创建合约实例
                const bountyContract = new ethers.Contract(addrBountySmartContract, contractBountyABI, provider.getSigner());

                // 准备交易参数
                const challengeBonusWei = ethers.utils.parseEther(challengeBonus.toString());

                // 估算gas
                const gasEstimate = await bountyContract.estimateGas.createChallenge(videoId, challengeDescription, { value: challengeBonusWei });

                // 发送交易
                const tx = await bountyContract.createChallenge(videoId, challengeDescription, {
                    value: challengeBonusWei,
                    gasLimit: gasEstimate.mul(120).div(100)
                });

                console.log('Challenge creation transaction sent:', tx.hash);

                // 等待交易确认
                const receipt = await tx.wait();
                console.log('Challenge creation transaction confirmed:', receipt.transactionHash);
            }

            // 如果选择了房间,先添加视频到房间
            if (selectedRoom) {
                const addToRoomResponse = await api.setRoomVideoAdd(selectedRoom, videoId, true);
                if (!addToRoomResponse.success) {
                    console.error("Failed to add video to room:", addToRoomResponse.msg);
                }
            }

            // 修改api.setCreation调用，添加challenge_nft_id参数
            const creationResponse = await api.setCreation(
                sessionCreation,
                userLoginData.userid,
                videoId,
                title,
                description,
                JSON.stringify(tags.map(tag => tag.value)),
                challengeNftId || '', // 使用challengeNftId作为challenge_nft_id
                visibility ? '0' : '1', // is_private
                price.toString(),
            );

            setIsLoading(false)
            if (creationResponse.success) {
                setShowSuccessModal(true);
            } else {
                throw new Error(creationResponse.msg || 'An error occurred during video creation');
            }

        } catch (error: unknown) {
            setIsLoading(false);
            setShowErrorModal(true);
            if (error instanceof Error) {
                setErrorMessage(error.message || 'An unknown error occurred');
            } else {
                setErrorMessage('An unknown error occurred');
            }
        }
    };

    const renderStepContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <>
                        <p>{stepContents[currentStep].content}</p>
                        <div className="mt-4">
                            <FilePond
                                ref={filePondRef}
                                files={files.map(file => file.file)}
                                onupdatefiles={(fileItems) => {
                                    setFiles(fileItems);
                                    if (fileItems.length === 0) {
                                        setIsUploadComplete(false);
                                    }
                                }}
                                allowMultiple={false}
                                maxFiles={1}
                                acceptedFileTypes={['video/*']}
                                minFileSize="5MB"
                                maxFileSize="2000MB"
                                credits={false}
                                server={{
                                    process: (_fieldName, file, _metadata, load, error, progress, abort) => {
                                        getSessionAndUploadToS3(
                                            file as File,
                                            load,
                                            error,
                                            (_percentage, chunk, total) => {
                                                progress(true, chunk, total);
                                            },
                                            abort
                                        );
                                        return {
                                            abort: () => {
                                                abort();
                                                setIsUploadComplete(false);
                                            }
                                        };
                                    },
                                }}
                                labelIdle='Drag & Drop your video or <span class="filepond--label-action">Browse</span>'
                            />
                        </div>
                        {showUploadWarning && (
                            <p className="text-red-500 mt-2">Please wait for the upload to complete before proceeding.</p>
                        )}
                    </>
                );
            case 1:
                return (
                    <>
                        <p>{stepContents[currentStep].content}</p>
                        {imageSizeError && (
                            <p className="text-red-500 mt-2">{imageSizeError}</p>
                        )}
                        <div className="mt-4">
                            {croppedImageUrl ? (
                                <div>
                                    <h3 className="text-lg font-semibold">Cropped Image Preview:</h3>
                                    <img src={croppedImageUrl} alt="Cropped cover" className="mt-2 max-w-full h-auto" />
                                    <button
                                        onClick={() => {
                                            setCroppedImageUrl(null);
                                        }}
                                        className="mt-4 px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-800"
                                    >
                                        Modify Image
                                    </button>
                                </div>
                            ) : (
                                coverImage && !imageSizeError && (
                                    <ImageCropper
                                        coverImage={coverImage}
                                        MIN_WIDTH={MIN_WIDTH}
                                        MIN_HEIGHT={MIN_HEIGHT}
                                        onSaveCrop={(croppedImage) => setCroppedImageUrl(croppedImage)}
                                        onReupload={handleReupload}
                                        initialAspectRatio={16 / 9} 
                                        {...({} as any)}
                                    />
                                )
                            )}
                            <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                accept="image/*,video/*"
                                className="hidden"
                            />
                        </div>
                    </>
                );
            case 2:
                return (
                    <>
                        <Input
                            label="Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="mb-4"
                        />
                        <Textarea
                            label="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="mb-4"
                        />
                        <div className="flex items-center space-x-4 pb-4">
                            <label className="whitespace-nowrap font-bold">Tags</label>
                            <div className="flex-grow">
                                <CreatableSelect<TagOption, true>
                                    isMulti
                                    options={defaultTags}
                                    value={tags}
                                    onChange={handleTagChange}
                                    className="basic-multi-select z-[9999]"
                                    classNamePrefix="select"
                                    formatCreateLabel={(inputValue: string) => `Create new tag: "${inputValue}"`}
                                />
                            </div>
                        </div>
                        <div className="flex items-center">
                            <span className="mr-2 font-bold">Access</span>
                            <Switch
                                isSelected={visibility}
                                onValueChange={setVisibility}
                            />
                            <span className="ml-2">
                                {visibility ? 'Public' : 'Private'}
                            </span>
                        </div>
                    </>
                );
            case 3:
                return (
                    <>
                        <div className="mb-4">
                            <Slider
                                label="Set video price"
                                size="sm"
                                step={0.01}
                                maxValue={2}
                                minValue={0}
                                value={price}
                                getValue={(payment) => `${payment} POL`}
                                onChange={(value) => setPrice(Number(value))}
                                classNames={{
                                    base: "max-w-md gap-3",
                                    track: "border-s-secondary-100",
                                    filler: "bg-gradient-to-r from-secondary-100 to-secondary-500"
                                }}
                                renderThumb={(props) => (
                                    <div
                                        {...props}
                                        className="group p-1 top-1/2 bg-background border-small border-default-200 dark:border-default-400/50 shadow-medium rounded-full cursor-grab data-[dragging=true]:cursor-grabbing"
                                    >
                                        <span className="transition-transform bg-gradient-to-br shadow-small from-secondary-100 to-secondary-500 rounded-full w-5 h-5 block group-data-[dragging=true]:scale-80" />
                                    </div>
                                )}
                            />
                        </div>
                        <Splitter
                            splitterOptions={splitterOptions}
                            setSplitterOptions={setSplitterOptions}
                        />
                        <div className="advanced-settings">
                            <Select
                                label="Select Room"
                                placeholder="Choose a room"
                                selectedKeys={selectedRoom ? [selectedRoom] : []}
                                onChange={(e) => setSelectedRoom(e.target.value)}
                            >
                                {rooms.map((room) => (
                                    <SelectItem key={room.id} value={room.id}>
                                        {room.name}
                                    </SelectItem>
                                ))}
                            </Select>
                        </div>
                        <div className="mt-4">
                            <Switch
                                isSelected={createChallenge}
                                onValueChange={setCreateChallenge}
                                size="sm"
                            >
                                Create Challenge
                            </Switch>
                        </div>
                        {createChallenge && (
                            <>
                                <div className="mt-4">
                                    <Slider
                                        label="Challenge Bonus"
                                        size="sm"
                                        step={1}
                                        maxValue={100}
                                        minValue={1}
                                        value={challengeBonus}
                                        getValue={(value) => `${value} POL`}
                                        onChange={(value) => setChallengeBonus(Number(value))}
                                        classNames={{
                                            base: "max-w-md gap-3",
                                            track: "border-s-secondary-100",
                                            filler: "bg-gradient-to-r from-secondary-100 to-secondary-500"
                                        }}
                                        renderThumb={(props) => (
                                            <div
                                                {...props}
                                                className="group p-1 top-1/2 bg-background border-small border-default-200 dark:border-default-400/50 shadow-medium rounded-full cursor-grab data-[dragging=true]:cursor-grabbing"
                                            >
                                                <span className="transition-transform bg-gradient-to-br shadow-small from-secondary-100 to-secondary-500 rounded-full w-5 h-5 block group-data-[dragging=true]:scale-80" />
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="mt-4">
                                    <Textarea
                                        label="Challenge Description"
                                        value={challengeDescription}
                                        onChange={(e) => setChallengeDescription(e.target.value)}
                                        placeholder="Describe your challenge..."
                                        isRequired={createChallenge}
                                        isInvalid={createChallenge && !isChallengeDescriptionValid}
                                        errorMessage={createChallenge && !isChallengeDescriptionValid ? "Challenge description is required" : ""}
                                    />
                                </div>
                            </>
                        )}
                    </>
                );
            case 4:
                return (
                    <>
                        <div className="space-y-4">
                            {croppedImageUrl && (
                                <div>
                                    <img src={croppedImageUrl} alt="Video cover"
                                         className="mt-2 max-w-full h-auto rounded-lg shadow-md"/>
                                </div>
                            )}
                            <p><strong>Title:</strong> {title}</p>
                            <p><strong>Description:</strong> {description}</p>
                            <p><strong>Tags:</strong> {tags.map(tag => tag.label).join(', ')}</p>
                            <p><strong>Access:</strong> {visibility ? 'Public' : 'Private'}</p>
                            <p><strong>Price:</strong> {price} POL</p>
                            <p><strong>Splitter Addresses:</strong></p>
                            <ul className="list-disc pl-5">
                                {splitterOptions.map((option, index) => (
                                    <li key={index}>{option.value}</li>
                                ))}
                            </ul>
                            {selectedRoom && (
                                <p><strong>Selected Room:</strong> {rooms.find(room => room.id === selectedRoom)?.name}</p>
                            )}
                            <p><strong>Create Challenge:</strong> {createChallenge ? 'Yes' : 'No'}</p>
                            {createChallenge && (
                                <>
                                    <p><strong>Challenge Bonus:</strong> {challengeBonus} POL</p>
                                    <p><strong>Challenge Description:</strong> {challengeDescription}</p>
                                </>
                            )}
                        </div>
                    </>
                );
            default:
                return <p>{stepContents[currentStep].content}</p>;
        }
    };

    useEffect(() => {
        if (ready) {
            setIsCheckingAuth(false);
        }
    }, [ready]);

    useEffect(() => {
        if (currentStep === 1 && files.length > 0 && !croppedImageUrl) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            extractFirstFrame(files[0].file);
        }
    }, [currentStep, files, croppedImageUrl]);

    useEffect(() => {
        if (currentStep === 2) {
            setIsStep3Valid(title.trim() !== '' && description.trim() !== '');
        }
    }, [currentStep, title, description]);

    useEffect(() => {
        if (showSuccessModal) {
            onSuccessModalOpen();
        }
    }, [onSuccessModalOpen, showSuccessModal]);

    useEffect(() => {
        fetchRooms();
    }, []);

    useEffect(() => {
        if (nftid) {
            setChallengeNftId(nftid);
        }
    }, [nftid]);

    useEffect(() => {
        if (createChallenge) {
            setIsChallengeDescriptionValid(challengeDescription.trim() !== '');
        } else {
            setIsChallengeDescriptionValid(true);
        }
    }, [createChallenge, challengeDescription]);

    const fetchRooms = async () => {
        try {
            const response: ApiResponse<RoomData> = await api.getRoom(userLoginData.userid);
            if (response.success) {
                const allRooms = [...response.data.joined, ...response.data.created];
                setRooms(allRooms);
            } else {
                console.error("Failed to fetch rooms:", response.msg);
            }
        } catch (error) {
            console.error("An error occurred while fetching rooms:", error);
        }
    };

    if (isCheckingAuth) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    if (!authenticated) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <Icon
                    icon="lets-icons:wallet-alt"
                    width={48}
                    className={"mb-8"}
                />
                <h1 className="text-xl font-bold mb-10">Continue after connecting your wallet</h1>
                <Button
                    color="primary"
                    size="lg"
                    onClick={login}
                >
                    Connect
                </Button>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center w-full max-w-4xl mx-auto px-4">
            {!authenticated ? (
                <div className="flex flex-col items-center justify-center h-screen">
                    <h1 className="text-2xl font-bold mb-4">Video Upload</h1>
                    <p className="mb-8">Please log in to continue</p>
                    <Button
                        color="primary"
                        size="lg"
                        onClick={login}
                    >
                        LogIn
                    </Button>
                </div>
            ) : (
                <>
                    <div className="w-full overflow-x-auto pb-4">
                        <div className="min-w-max">
                            <HorizontalSteps
                                currentStep={currentStep}
                                steps={stepContents}
                                onStepChange={handleStepChange}
                            />
                        </div>
                    </div>
                    <div className="mt-8 w-full p-4 border rounded-lg">
                        <h2 className="text-xl font-bold mb-4">{stepContents[currentStep].title}</h2>
                        {renderStepContent()}
                    </div>

                    {isLoading && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                            <div className="bg-white p-8 rounded-lg text-center">
                                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 mx-auto mb-4"></div>
                                <p className="text-xl font-semibold">{loadingStatus}</p>
                            </div>
                        </div>
                    )}

                    <Modal
                        isOpen={isSuccessModalOpen}
                        onClose={onSuccessModalClose}
                        isDismissable={false}
                        hideCloseButton
                    >
                        <ModalContent>
                            {() => (
                                <>
                                    <ModalHeader className="flex flex-col gap-1">Congratulations! 🎉</ModalHeader>
                                    <ModalBody>
                                        <p>Your video has been successfully uploaded and published.</p>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="primary" onPress={handleViewVideo}>
                                            View Video
                                        </Button>
                                    </ModalFooter>
                                </>
                            )}
                        </ModalContent>
                    </Modal>

                    <Modal
                        isOpen={showErrorModal}
                        onClose={() => setShowErrorModal(false)}
                    >
                        <ModalContent>
                            {(onClose) => (
                                <>
                                    <ModalHeader className="flex flex-col gap-1">Error</ModalHeader>
                                    <ModalBody>
                                        <p>{errorMessage}</p>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="danger" variant="light" onPress={onClose}>
                                            Close
                                        </Button>
                                    </ModalFooter>
                                </>
                            )}
                        </ModalContent>
                    </Modal>

                    <div className="flex justify-between w-full mt-4">
                        <Button
                            onClick={handlePrevious}
                            disabled={currentStep === 0 || (currentStep === 1 && step1Completed)}
                            color="primary"
                            variant="bordered"
                            className="disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            Previous
                        </Button>
                        {currentStep === stepContents.length - 1 ? (
                            <Button
                                onClick={handleSubmit}
                                disabled={isLoading || !isChallengeDescriptionValid}
                                color="success"
                                className="disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                {isLoading ? 'Submitting...' : 'Submit'}
                            </Button>
                        ) : (
                            <Button
                                onClick={handleNext}
                                disabled={
                                    (currentStep === 0 && !isUploadComplete) ||
                                    (currentStep === 1 && !croppedImageUrl) ||
                                    (currentStep === 2 && !isStep3Valid) ||
                                    (currentStep === 3 && !isChallengeDescriptionValid)
                                }
                                color="primary"
                                className="disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                Next
                            </Button>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
