import { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Input, Button, Avatar, Pagination, Chip, Divider } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import { api, Room } from "../services/api";

// 首先定义新的接口类型
interface SearchResponse {
    keyword: string;
    total: number;
    rooms: Array<{
        id: number;
        name: string;
        description: string;
        timestamp: number;
        author: string;
        author_name: string;
        author_avatar: string;
        followers_count: number;
        videos_count: number;
        match_priority: number;
    }>;
}

export default function RoomList({ onCreateRoom }: { onCreateRoom: () => void }) {
    const [rooms, setRooms] = useState<Room[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isSearching, setIsSearching] = useState(false);
    const [currentSearchTerm, setCurrentSearchTerm] = useState("");
    const itemsPerPage = 12;

    // 搜索房间
    const searchRooms = useCallback(async (keyword: string, page: number) => {
        setIsLoading(true);
        try {
            const offset = (page - 1) * itemsPerPage;
            const response = await api.getRoomSearch(keyword, offset);
            if (response.success) {
                // 先将 response.data 转换为 unknown 类型，再转换为 SearchResponse
                const searchData = (response.data as unknown) as SearchResponse;
                // 将搜索结果转换为 Room 类型
                const roomsData = searchData.rooms.map(room => ({
                    id: room.id.toString(), // 转换为字符串以匹配 Room 类型
                    name: room.name,
                    is_private: 0, // 假设搜索结果中没有这个字段，默认为公开
                    timestamp: room.timestamp
                }));
                setRooms(roomsData);
                // 使用返回的总数计算总页数
                setTotalPages(Math.ceil(searchData.total / itemsPerPage));
            }
        } catch (error) {
            console.error('Failed to search rooms:', error);
        } finally {
            setIsLoading(false);
        }
    }, [itemsPerPage]);

    // 获取所有房间
    const fetchRooms = useCallback(async (page: number) => {
        setIsLoading(true);
        try {
            const offset = (page - 1) * itemsPerPage;
            const response = await api.getRoomAll(offset);
            if (response.success) {
                setRooms(response.data as unknown as Room[]);
                setTotalPages(Math.ceil(100 / itemsPerPage));
            }
        } catch (error) {
            console.error('Failed to fetch rooms:', error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    // 处理搜索
    const handleSearch = useCallback(() => {
        const trimmedQuery = searchQuery.trim();
        if (trimmedQuery) {
            setIsSearching(true);
            setCurrentPage(1);
            setCurrentSearchTerm(trimmedQuery);
            searchRooms(trimmedQuery, 1);
        } else {
            setIsSearching(false);
            setCurrentPage(1);
            setCurrentSearchTerm("");
            fetchRooms(1);
        }
    }, [searchQuery, searchRooms, fetchRooms]);

    // 处理搜索输入
    const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    // 处理按键事件
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && !e.nativeEvent.isComposing) {
            handleSearch();
        }
    };

    // 处理页面变化
    useEffect(() => {
        if (isSearching) {
            searchRooms(currentSearchTerm, currentPage);
        } else {
            fetchRooms(currentPage);
        }
    }, [currentPage, isSearching, searchRooms, fetchRooms, currentSearchTerm]);

    const handleRoomClick = (roomId: string) => {
        // 在新标签页打开
        window.open(`/room/${roomId}`, '_blank');
    };

    return (
        <div className="w-full max-w-7xl mx-auto p-4 space-y-8">
            {/* 标题和创建按钮 */}
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-8">
                <div className="flex-1">
                    <h1 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
                        Rooms
                    </h1>
                    <p className="text-default-500 mt-1">
                        Discover and join interesting rooms
                    </p>
                </div>
                <Button 
                    color="primary" 
                    endContent={<Icon icon="material-symbols:add" />}
                    onClick={onCreateRoom}
                    size="lg"
                    className="w-full sm:w-auto bg-gradient-to-r from-primary to-secondary"
                >
                    Create Room
                </Button>
            </div>

            {/* 搜索栏 */}
            <div className="relative">
                <Input
                    placeholder="Search rooms by name..."
                    value={searchQuery}
                    onChange={handleSearchInput}
                    onKeyDown={handleKeyDown}
                    size="lg"
                    radius="lg"
                    classNames={{
                        inputWrapper: "shadow-sm hover:shadow transition-shadow duration-200",
                    }}
                    endContent={
                        <>
                            {searchQuery && (
                                <div className="flex items-center">
                                    <Divider orientation="vertical" className="h-5 mx-2" />
                                    <Button 
                                        isIconOnly
                                        variant="light" 
                                        size="sm"
                                        onClick={() => {
                                            setSearchQuery('');
                                            setIsSearching(false);
                                            fetchRooms(1);
                                        }}
                                    >
                                        <Icon icon="material-symbols:close" className="text-default-400" />
                                    </Button>
                                </div>
                            )}
                            <Button
                                size="sm"
                                variant="flat"
                                color="secondary"
                                isLoading={isLoading}
                                onClick={handleSearch}
                                className="min-w-[80px] h-8 mx-1"
                                startContent={!isLoading && <Icon icon="material-symbols:search" />}
                            >
                                Search
                            </Button>
                        </>
                    }
                />
            </div>

            {/* 搜索结果状态提示 */}
            {isSearching && (
                <div className="flex items-center justify-between text-default-500 text-sm px-1">
                    <span>
                        Found {rooms.length} results for "{currentSearchTerm}"
                    </span>
                    <Button 
                        size="sm"
                        variant="light" 
                        color="secondary"
                        onClick={() => {
                            setSearchQuery('');
                            setIsSearching(false);
                            fetchRooms(1);
                        }}
                    >
                        Clear search
                    </Button>
                </div>
            )}

            {/* 房间列表内容 */}
            {isLoading ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {[...Array(6)].map((_, i) => (
                        <Card key={i} className="w-full h-48 bg-default-100" />
                    ))}
                </div>
            ) : rooms.length === 0 ? (
                <div className="flex flex-col items-center justify-center py-12 text-default-500">
                    <Icon icon="material-symbols:search-off" className="text-5xl mb-4" />
                    <p className="text-xl">No rooms found</p>
                    {searchQuery && (
                        <p className="mt-2">
                            Try different keywords or{' '}
                            <Button 
                                color="primary" 
                                variant="light" 
                                onClick={() => {
                                    setSearchQuery('');
                                    setIsSearching(false);
                                    fetchRooms(1);
                                }}
                            >
                                clear search
                            </Button>
                        </p>
                    )}
                </div>
            ) : (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {rooms.map((room) => (
                            <Card 
                                key={room.id}
                                isPressable
                                onPress={() => handleRoomClick(room.id)}
                                className="group relative overflow-hidden bg-content1 transition-all duration-300 ease-in-out 
                                hover:scale-[1.01] hover:shadow-lg hover:-translate-y-0.5
                                hover:ring-2 hover:ring-primary/20 dark:hover:ring-primary/30"
                            >
                                <CardBody className="overflow-visible p-4">
                                    <div className="flex gap-4">
                                        <Avatar
                                            src={`https://api.dicebear.com/6.x/initials/svg?seed=${room.name}`}
                                            className="w-12 h-12 transition-transform duration-300 group-hover:scale-105"
                                            isBordered
                                        />
                                        <div className="flex-1">
                                            <h4 className="text-lg font-semibold line-clamp-1 group-hover:text-primary transition-colors">
                                                {room.name}
                                            </h4>
                                            <div className="flex items-center gap-2 mt-1">
                                                <Chip 
                                                    size="sm" 
                                                    color={room.is_private ? "danger" : "success"}
                                                    variant="flat"
                                                    className="transition-colors duration-300"
                                                >
                                                    <span className="flex items-center gap-1">
                                                        <Icon 
                                                            icon={room.is_private ? "ri:lock-fill" : "ri:earth-fill"} 
                                                            className="text-sm"
                                                        />
                                                        {room.is_private ? "Private" : "Public"}
                                                    </span>
                                                </Chip>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardFooter className="text-small text-default-500 border-t border-default-100">
                                    <div className="flex justify-between w-full items-center">
                                        <span className="flex items-center gap-2">
                                            <Icon icon="material-symbols:calendar-month" />
                                            {new Date(room.timestamp * 1000).toLocaleDateString()}
                                        </span>
                                        <div className="flex items-center gap-1 group-hover:text-primary transition-colors">
                                            <span>Open in new tab</span>
                                            <Icon 
                                                icon="material-symbols:open-in-new"
                                                className="transition-transform duration-300 group-hover:translate-x-0.5"
                                            />
                                        </div>
                                    </div>
                                </CardFooter>
                                <div className="absolute inset-0 bg-gradient-to-r from-primary/5 via-transparent to-secondary/5 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                            </Card>
                        ))}
                    </div>

                    <div className="flex justify-center mt-8">
                        <Pagination
                            total={totalPages}
                            page={currentPage}
                            onChange={setCurrentPage}
                            showControls
                            className="gap-2"
                            classNames={{
                                cursor: "bg-gradient-to-r from-primary to-secondary text-white",
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    );
} 